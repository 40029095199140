import { AspectRatio, Box, Flex, Image as ChakraImage, Stack, Text } from "@chakra-ui/react";
import { useSpTheme } from "@streamparty/app-ui/lib";
import { ProgressBar } from "@streamparty/app-ui/lib/components";
import { isFuture } from "date-fns";
import useTranslation from "next-translate/useTranslation";
import React, { FunctionComponent, useRef } from "react";

import { getIconForStreamingHost } from "../util/content";
import { getDateRelativeToNow } from "../util/time";
import { NextImage } from "./NextImage";

interface PartyImageProps {
  host: string;
  title: string;
  src?: string;
  viewerCount: number;
  startDate: Date;
  imageSize: "sm" | "lg";
  index: number;
}

const humanDate = (date: Date, lang: string): string => getDateRelativeToNow(lang, date);

export const PartyImage: FunctionComponent<PartyImageProps> = ({
  title,
  src,
  host,
  viewerCount,
  startDate,
  imageSize,
  index,
}) => {
  const { t, lang } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const { colors } = useSpTheme();

  const isFutureTime = isFuture(startDate);

  let width = 360;
  let height = 200;

  if (imageSize == "lg") {
    width = 810;
    height = 450;
  }

  const getDisplayNoImage = (host: string) => {
    const imageUrl = getIconForStreamingHost(host);

    return (
      <Stack justify="center" align="center" w="100%" h="100%" overflowX="hidden">
        <Box h="50%" opacity={0.5}>
          <ChakraImage w="100%" height="100%" src={imageUrl} />
        </Box>
        <Text
          w="100%"
          fontWeight="bold"
          overflow="hidden"
          whiteSpace="nowrap"
          textAlign="center"
          style={{
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </Text>
      </Stack>
    );
  };

  return (
    <Box ref={ref} shadow="lg">
      <AspectRatio w="100%" ratio={9 / 5}>
        <Box>
          {src && !src.startsWith("https://i.ytimg") && (
            <NextImage
              quality={imageSize == "sm" ? 50 : 100}
              width={width}
              height={height}
              alt={`Party Image for ${title}`}
              src={src}
              loading={index < 5 ? "eager" : "lazy"}
            />
          )}
          {src && src.startsWith("https://i.ytimg") && (
            <ChakraImage alt={`Party Image for ${title}`} width="100%" height="100%" src={src} />
          )}
          {!src && host && (
            <Flex
              display="flex"
              h="100%"
              w="100%"
              backgroundImage={`linear-gradient(257deg, rgba(227, 32, 0, 0.6), rgba(255, 0, 111, 0.6))`}
              justifyContent="center"
              alignItems="center"
              paddingX={8}
              paddingY={8}
            >
              {getDisplayNoImage(host)}
            </Flex>
          )}
          {viewerCount != 0 && (
            <Box
              position="absolute"
              left="10px"
              top="8px"
              backgroundColor="rgba(0,0,0,0.6)"
              color="white"
              py={0.5}
              rounded="sm"
              px={1}
            >
              <Text fontSize={12}>{t("common:participant_count", { count: viewerCount })}</Text>
            </Box>
          )}
          <Box
            position="absolute"
            right="10px"
            top="10px"
            backgroundImage={
              !isFutureTime
                ? `linear-gradient(257deg, ${colors.redOrange[600]}, ${colors.magenta[400]})`
                : ""
            }
            backgroundColor={isFutureTime ? "gray.700" : ""}
            py={0.5}
            px={1}
          >
            <Text fontSize={12}>{!isFutureTime ? t`common:live` : humanDate(startDate, lang)}</Text>
          </Box>
          <Box position="absolute" bottom={0} left={0} w="full" px={2} py={1}>
            <ProgressBar value={!isFutureTime ? 20 : 0} isSmall rounded="full" />
          </Box>
        </Box>
      </AspectRatio>
    </Box>
  );
};
