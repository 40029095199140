import { Box } from "@chakra-ui/react";
import { RoundIconButton } from "@streamparty/app-ui/lib/components";
import { BsArrowLeftShort, BsArrowRightShort } from "@streamparty/app-ui/lib/icons";
import React, { forwardRef, FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import Swipe from "react-easy-swipe";
import AutoSizer from "react-virtualized-auto-sizer";

import { debounce } from "../util/debounce";
import { useBreakpointValueOrDefault } from "../util/media";
import { removeEmptyKeys } from "../util/object";
import { AnimatedGrid, GridChildProps, ScrollRef } from "./AnimatedGrid";

const innerElementType = ({ paddingLeftPercentage }: { paddingLeftPercentage: number }) =>
  // @ts-ignore
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLDivElement>(({ style, ...rest }, ref) => (
    <div
      ref={ref}
      style={{
        ...style,
        paddingLeft: paddingLeftPercentage + "%",
      }}
      {...rest}
    />
  ));

innerElementType.displayName = "InnerElement";

type NextButtonProps = {
  direction: "left" | "right";
  arrowEnabled: boolean;
  visible: boolean;
  onClick: () => void;
  paddingLeftPercentage?: number;
  paddingBetweenPx?: number;
  imageHeightPx: number;
};

const NextButton: FunctionComponent<NextButtonProps> = ({
  arrowEnabled,
  visible,
  onClick,
  direction,
  paddingLeftPercentage = 0,
  paddingBetweenPx = 0,
  imageHeightPx,
}) => {
  const positioningProps = {
    right: direction == "right" ? 0 : undefined,
    left: direction == "left" ? 0 : undefined,
  };

  const arrow = direction == "left" ? BsArrowLeftShort : BsArrowRightShort;
  const arrowPositioningProps = {
    marginRight: direction == "right" ? 1 : 0,
    marginLeft: direction == "left" ? 1 : 0,
  };

  if (!visible) {
    return <></>;
  }

  return (
    <Box
      position="absolute"
      top={0}
      bottom={0}
      {...positioningProps}
      width={`calc(${paddingLeftPercentage}% + ${paddingBetweenPx}px)`}
    >
      <Box
        position="absolute"
        top={0}
        bottom={`calc(100% - ${imageHeightPx}px)`}
        right={0}
        backgroundImage={`linear-gradient(to ${direction}, rgba(0, 0, 0, 0), #201f1f)`}
        zIndex={20}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent={direction == "right" ? "flex-end" : "flex-start"}
        onClick={(event) => event.preventDefault()}
      >
        {arrowEnabled && (
          <RoundIconButton
            aria-label="More"
            h="32px"
            w="32px"
            size="sm"
            borderColor="rgba(151, 151, 151, 0.2)"
            {...arrowPositioningProps}
            as={arrow}
            zIndex={40}
            onClick={onClick}
          />
        )}
      </Box>
    </Box>
  );
};

export type CarouselItemSize = {
  width: number;
  height: number;
  imageHeight: number;
};

export type CarouselItemSizeBreakpoints = {
  base?: CarouselItemSize;
  sm?: CarouselItemSize;
  md?: CarouselItemSize;
};

export interface CarouselV2Props {
  data: unknown[];
  paddingBetweenPx: number;
  paddingLeftPercentage: number;
  itemSize: CarouselItemSizeBreakpoints;
  childComponent: FunctionComponent<GridChildProps>;
  onScrolled?: (fromEnd: number) => void;
}

const expandWithPadding = (
  item: CarouselItemSize | undefined,
  paddingBetween: number
): CarouselItemSize | undefined => {
  if (!item) return undefined;

  return { ...item, width: item.width + paddingBetween };
};

export const CarouselV2: FunctionComponent<CarouselV2Props> = (props) => {
  const {
    data,
    itemSize,
    paddingBetweenPx,
    paddingLeftPercentage,
    childComponent,
    onScrolled: onScrolledCb,
  } = props;

  const scrollRef = useRef<ScrollRef>();
  const startIndex = useRef(0);
  const diff = useRef(1);
  const debounced = useRef(
    debounce((end: number) => {
      onScrolledCb?.(data.length - (end + 1));
    }, 500)
  );
  const [nextArrowVisible, setNextArrowVisible] = useState(false);
  const [prevArrowVisible, setPrevArrowVisible] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [mounted, setMounted] = useState(false);

  const cardSize = useBreakpointValueOrDefault<CarouselItemSize | undefined>(
    removeEmptyKeys({
      base: expandWithPadding(itemSize.base, paddingBetweenPx),
      sm: expandWithPadding(itemSize.sm, paddingBetweenPx),
      md: expandWithPadding(itemSize.md, paddingBetweenPx),
    }),
    expandWithPadding(itemSize.sm, paddingBetweenPx) ??
      expandWithPadding(itemSize.md, paddingBetweenPx)
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  const innerElement = useMemo(() => {
    return innerElementType({ paddingLeftPercentage });
  }, [paddingLeftPercentage]);

  const carousel = useMemo(() => {
    const onScrolled = (start: number, end: number) => {
      startIndex.current = start;
      diff.current = end - start + 1;
      if (!data[startIndex.current + diff.current]) {
        setNextArrowVisible(false);
      } else {
        setNextArrowVisible(true);
      }
      if (!data[startIndex.current - 1]) {
        setPrevArrowVisible(false);
      } else {
        setPrevArrowVisible(true);
      }
      debounced?.current(end);
    };

    return (
      <AutoSizer disableHeight defaultHeight={cardSize?.height ?? 270} defaultWidth={1440}>
        {({ width }) => {
          return (
            <AnimatedGrid
              className="grid"
              style={{
                overflow: "hidden !important",
              }}
              columnCount={data.length + 10}
              columnWidth={cardSize?.width ?? 100}
              innerElementType={innerElement}
              height={cardSize?.height ?? 100}
              rowCount={1}
              rowHeight={cardSize?.height ?? 100}
              overscanColumnCount={2}
              width={width}
              childComponent={childComponent}
              data={data}
              scrollRef={scrollRef}
              onScrolled={onScrolled}
              paddingBetweenPx={paddingBetweenPx}
              paddingLeftPercentage={paddingLeftPercentage}
            />
          );
        }}
      </AutoSizer>
    );
  }, [data, innerElement, cardSize]);

  if (!mounted) {
    const Component = childComponent;

    const height = [
      itemSize.base?.height ?? null,
      itemSize.sm?.height ?? null,
      itemSize.md?.height ?? null,
    ];

    const width = [
      itemSize.base?.width ?? null,
      itemSize.sm?.width ?? null,
      itemSize.md?.width ?? null,
    ];

    const cards = data.slice(0, 10).map((dataItem, index) => {
      return (
        <Box
          key={index}
          height={"100%"}
          width={width}
          minHeight={"100%"}
          minWidth={width}
          overflowY="hidden"
          marginLeft={index == 0 ? "4%" : paddingBetweenPx + "px"}
        >
          <Component data={dataItem} index={index} />
        </Box>
      );
    });

    // SSR
    return (
      <Box
        width="100vw"
        height={height.map((item) => (item ? item : item))}
        display="flex"
        flexDirection="row"
        position="relative"
      >
        {cards}
      </Box>
    );
  }

  return (
    <Swipe
      innerRef={() => {
        // ignore inner ref
      }}
      onSwipeRight={() => {
        if (data[startIndex.current - diff.current]) {
          scrollRef.current?.scrollTo(startIndex.current - diff.current);
        }
      }}
      onSwipeLeft={() => {
        if (data[startIndex.current + diff.current]) {
          scrollRef.current?.scrollTo(startIndex.current + diff.current);
        }
      }}
      tolerance={80}
    >
      <Box
        position="relative"
        onMouseEnter={() => {
          setShowButtons(true);
        }}
        onMouseLeave={() => {
          setShowButtons(false);
        }}
      >
        {carousel}
        <NextButton
          direction="right"
          paddingLeftPercentage={4}
          paddingBetweenPx={15}
          arrowEnabled={showButtons}
          visible={nextArrowVisible}
          imageHeightPx={cardSize?.imageHeight ?? 240}
          onClick={() => {
            const index = Math.min(startIndex.current + diff.current, data.length);
            if (data[index]) {
              scrollRef.current?.scrollTo(index);
            }
          }}
        />
        <NextButton
          direction="left"
          paddingLeftPercentage={4}
          paddingBetweenPx={15}
          arrowEnabled={showButtons}
          visible={prevArrowVisible}
          imageHeightPx={cardSize?.imageHeight ?? 240}
          onClick={() => {
            const index = Math.max(startIndex.current - diff.current, 0);
            if (data[index]) {
              scrollRef.current?.scrollTo(index);
            }
          }}
        />
      </Box>
    </Swipe>
  );
};
