// @ts-ignore
import ease from "ease-component";
import debounce from "lodash.debounce";
import React, {
  forwardRef,
  FunctionComponent,
  memo,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import {
  areEqual,
  FixedSizeGrid,
  FixedSizeGrid as Grid,
  FixedSizeGridProps,
  GridChildComponentProps,
  GridOnScrollProps,
} from "react-window";
import scroll from "scroll";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface GridChildProps<T = any> extends Record<string, unknown> {
  data: T;
  index: number;
}

const Cell = (
  Child: FunctionComponent<GridChildProps>,
  paddingLeftPercentage = 0,
  paddingBetweenPx: number
) =>
  // eslint-disable-next-line react/display-name
  memo(({ style, columnIndex, data }: GridChildComponentProps) => {
    const childItem = useMemo(() => {
      if (!data[columnIndex]) return <></>;
      return <Child data={data[columnIndex]} index={columnIndex} />;
    }, [columnIndex, data]);

    return useMemo(() => {
      return (
        <div
          style={{
            ...style,
            // @ts-ignore
            left: `calc(${style.left}px + ${paddingLeftPercentage}%)`,
            // @ts-ignore
            width: style.width - paddingBetweenPx,
          }}
          className="cell"
        >
          {childItem}
        </div>
      );
    }, [style.left, style.width, childItem]);
  }, areEqual);

interface AnimatedGridProps extends Omit<FixedSizeGridProps, "children"> {
  duration?: number;
  childComponent: FunctionComponent<GridChildProps>;
  data: unknown[];
  scrollRef: MutableRefObject<ScrollRef | undefined>;
  onScrolled(begin: number, end: number): void;
  paddingBetweenPx: number;
  paddingLeftPercentage?: number;
}

export type ScrollRef = {
  scrollTo: (item: number) => void;
};

export const AnimatedGrid = forwardRef<FixedSizeGrid, AnimatedGridProps>((props, ref) => {
  const gridRef = useRef<HTMLDivElement>();
  const scrollLeftPos = useRef(0);
  const isScrolling = useRef(false);
  const updateProps = useRef(props);

  useEffect(() => {
    updateProps.current = props;
  }, [props]);

  const triggerUpdate = () => {
    const individualWidth = props.columnWidth + props.paddingBetweenPx;
    const visibleItem = Math.ceil(scrollLeftPos.current / individualWidth);
    const lastVisible = getColumnStopIndexForStartIndex(visibleItem, scrollLeftPos.current);
    props.onScrolled(visibleItem, lastVisible);
  };
  const triggerUpdateDebounce = useRef(debounce(triggerUpdate, 200, { maxWait: 200 }));

  useImperativeHandle(props.scrollRef, () => ({
    scrollTo: (item) => {
      const { columnWidth, duration = 500 } = props;

      if (isScrolling.current) return false;

      isScrolling.current = true;

      if (gridRef.current)
        scroll.left(
          gridRef.current,
          Math.max(item * columnWidth - props.paddingBetweenPx, 0),
          { duration, ease: ease.inOutQuart },
          () => {
            isScrolling.current = false;
          }
        );
      return true;
    },
  }));

  const getColumnStopIndexForStartIndex = (startIndex: number, scrollLeft: number): number => {
    const props = updateProps.current;

    const left = startIndex * (props.columnWidth + props.paddingBetweenPx);
    const numVisibleColumns = (props.width + scrollLeft - left) / props.columnWidth;

    return Math.max(
      0,
      Math.min(
        props.columnCount - 1,
        startIndex + Math.round(numVisibleColumns) - 1 // -1 is because stop index is inclusive
      )
    );
  };

  const onScroll = ({ scrollLeft }: GridOnScrollProps) => {
    scrollLeftPos.current = scrollLeft;
    triggerUpdateDebounce.current();
  };

  const onItemsRendered = () => {
    triggerUpdateDebounce.current();
  };

  const CellWrapper = useMemo(() => {
    return Cell(props.childComponent, props.paddingLeftPercentage, props.paddingBetweenPx);
  }, [props.childComponent, props.paddingLeftPercentage, props.paddingBetweenPx]);

  return (
    <Grid
      className="grid"
      {...props}
      outerRef={gridRef}
      ref={ref}
      itemData={props.data}
      onItemsRendered={onItemsRendered}
      onScroll={onScroll}
    >
      {CellWrapper}
    </Grid>
  );
});

AnimatedGrid.displayName = "AnimatedGrid";
