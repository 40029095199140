import { useBreakpointValue } from "@chakra-ui/react";

export const useBreakpointValueOrDefault = <T = unknown>(
  values: Record<string, T> | T[],
  defaultValue: T
) => {
  const value = useBreakpointValue(values);

  if (!value) {
    return defaultValue;
  }

  return value;
};
